.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.6);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.Menu {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  bottom: 7px;
  right: 7px;
  height: 40px;
  width: 40px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #282c34;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.Modal .buttons {
  display: flex;
  flex-direction: column;
}

button {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  color: #282c34;
  padding: 10px 10px;
  margin: 10px 0;
  cursor: pointer;
}

.button:disabled,
.button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #282c34;
}

.Reset {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  bottom: 7px;
  left: 7px;
  padding: 5px 7px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #282c34;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.red-dot {
  height: 12px;
  width: 12px;
  margin-top: -4px;
  margin-right: -4px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.Modal .link {
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
}